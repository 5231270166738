interface Credentials {
  "Client ID": string;
  "Client Secret": string;
}

const credentials: Credentials = {
  "Client ID": "9a72d2a59e554d82992e467c723fc1bc",
  "Client Secret": "cfd213515b6741ffb6e8029bcae2bee2",
};

export default credentials;
